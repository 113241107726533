.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.hero-button {
  background-color: #E86A33;
  font-weight: bold;
  color: white;
  border: 1px solid #EC875B;
  margin: auto 40px;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.hero-button:hover {
  background-color: #EC875B;
}

.hero-button:focus {
  background-color: #E86A33;
}

.green-btn {
  background-color: #41644A !important;
  border: 1px solid #41644A;
}

.green-btn:hover {
  background-color: #263A29 !important;
}

.green-btn:focus {
  background-color: #41644A !important;
}

.hp-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  justify-content: space-between;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hp-container::-webkit-scrollbar {
  display: none;
}

.hb {
  width: 200px;
  margin-right: 16px;
}

.home-product-img {
  width: 200px;
  margin: 0px;
}

.hb-title {
  text-align: center;
  font-size: 18px;
}

.hbp {
  text-align: left;
  font-size: 12px;
  font-weight: 300;
}

.category-title {
  font-weight: 300;
  font-size: 36px;
  margin-top: 36px;
  margin-bottom: 24px;
}

.tg-cover {
  width: 100%;
}

.tg-icon {
  color: black;
  font-size: 46px;
  font-weight: bold;
}

.up-button {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 20px;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 35px;
  line-height: 55px;
  transition: 200ms;
  display: none;
}

.up-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transition: 200ms;
}


@media only screen and (max-width: 1024px) {

  .hb {
    width: 160px !important;
    margin-right: 16px !important;
  }

  .home-product-img {
    width: 160px;
  }

  .tg-icon {
    font-size: 26px;
  }

  select:focus,
  input:focus {
    font-size: 16px;
    background: #fff;
  }

  .hero-button {
    margin: 24px !important;
    margin-bottom: 40px;
  }
}