.pp-container {
    display: flex;
    margin: 20px 0px
}

.pp-content {
    width: 60%;
    padding: 48px;
}

.pp-img-container {
    width: 40%;
    margin: auto 48px auto 0px;

}

.pp-img-container-r {
    width: 40%;
    margin: auto 0px auto 32px;
}

.pp-img {
    width: 100%;
}

.pp-h {
    font-weight: 700;
    font-size: 28px;
}

.pp-p {
    color: #707070;
    font-weight: 300;
    font-size: 16px;
}

.pp-price {
    color: #41644A;
    font-weight: 800;
    font-size: 24px;
}

@media only screen and (max-width: 1024px) {
    .pp-container {
        display: block;
        margin: 20px 0px
    }

    .pp-content {
        width: auto;
        padding: 16px;
    }

    .pp-img-container {
        width: 100%;
        margin: 20px 0px;

    }

    .pp-img-container-r {
        width: 100%;
        margin: 20px 0px;

    }
    .pp-btn{
        margin: 20px 0px !important;
    }
}