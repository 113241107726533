body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth !important;
}

a {
  text-decoration: none;
  color: black;
}

.tpc {
  max-width: 1360px;
  margin: auto;
  padding: 0px 32px;
}


@media only screen and (max-width: 1024px) {
  .tpc {
    max-width: 1024px;
    margin: auto;
    padding: 0px 16px;
  }
}