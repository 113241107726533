.sub-h {
    font-size: 40px;
    font-weight: lighter;
    text-align: center;
    padding: 0px;
    margin-top: 24px;
}

.inline-spec {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact-btn {
    margin: 20px 0px 20px auto;
}

.contact-input {
    margin: 8px 0px;
}

@media only screen and (max-width: 720px) {
    .inline-spec {
        justify-content: left;
    }
    .contact-btn {
        margin: 24px 0px !important;
        width: 100%;
    }
}