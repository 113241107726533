.pc-container{
    display: flex;
    margin: 30px 0px;
    background-color: #F2E3DB;
}
.pc-carousel{
    max-width: 680px;
}

.pc-image{
    width: 100%;
}

.pc-content{
    padding: 0px 40px;
    margin: auto;

}

.pc-h1{
    font-size: 2.6em;
}
.pc-h2{
    font-size: 2em;
    font-weight: 400;
}
.pc-h3{
    font-weight: 300;
    font-size: 1.4em;
    margin-bottom: 20px;
}
.pc-next{
    font-size: 100px;
    position: absolute;   
    left: 800px;
    z-index: 99;
}

@media only screen and (max-width: 1360px) {
    .pc-h1{
        font-size: 2em;
    }
    .pc-h2{
        font-size: 1.6em;
    }
    .pc-h3{
        font-size: 1.2em;
    }
}
@media only screen and (max-width: 1160px) {
    .pc-h1{
        font-size: 1.8em;
    }
    .pc-h2{
        font-size: 1.4em;
    }
    .pc-h3{
        font-size: 1em;
    }
    .pc-content{
        padding: 0px 20px;
        margin: auto;
    }
}
@media only screen and (max-width: 900px) {
    .pc-container{
        flex-wrap:wrap-reverse;
    }
    .pc-carousel{
    max-width: 900px;
    width: 100%;
}
    .pc-image{
        width: 100%;
    }
    .pc-content{
        padding: 20px;
    }
    .pc-h1{
        font-size: 1.8em;
    }
    .pc-h2{
        font-size: 1.4em;
        font-weight: 400;
    }
    .pc-h3{
        font-weight: 300;
        font-size: 1em;
        margin-bottom: 20px;
    }
}