#header-top{
    height: 76px;
    max-width: 1360px;
    margin: 0px auto;
    padding: 0px 32px;
    display: flex;
    justify-content: space-between;
}
header{
    position: sticky !important;
    top: 0;
    background-color: white;
    z-index: 999;
}
.product-link{
    color: #41644A;
}
.product-link:hover{
    color: #E86A33;
}
.logo{
    height: 52px;
    margin: 12px 0px;
}

.move-icon{
    color: black ;
    font-size: 26px;
    font-weight: bold;
  }

.head-support{
    margin: 20px 30px;
    font-size: 20px;
    color: black !important;
}
.support-container {
    display: flex;
    background-color: #fff;
    padding: 20px 10px;
    margin-top: 10px;
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.75);
}
.support-small{
    padding: 0px 10px;

}
.support-small > b {
    font-size: 12px;
}
.support-link {
    display: block;
    margin: 5px 0px;
    color: #41644A;
}
.support-link:hover{
    color: #E86A33;
}
iframe{
    overflow-y: hidden;
}

#header-bar{
    height: 8px;
    background-color: #F2E3DB;
}

#header-menu{
    margin: 4px 0px;
    text-align: center;
}

.ant-dropdown-link{
    margin: 0px 12px;
    color: black;
    font-size: 16px !important;
    line-height: 76px;
    padding: 0px 10px;
}
.contact{
    padding-right: 0px !important;
    margin-right: 0px !important;
}
.ant-dropdown-link:hover{

    color: #E86A33;
}

.dropdown{
    background-color: #fff;
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.75);
    padding: 20px;
    display: inline-flex;
}

.dropdown-image-container{
    width: 300px;
}
.dropdown-menu{
    min-width: 200px;
}

.dropdown-image{
    width: 250px;
    height: 250px;
    margin: 25px;
}

.header-news{
    width: 100%;
    height: 30px;
    background-color: rgb(131,	27,	41);
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
}
.six-00 {
    width: 600px !important
}

.mobile-link{
    font-size: 16px;
    margin-left: 8px;
}

@media only screen and (max-width: 1024px) {
    
    .head-support{
        margin: 0px 0px;
        font-size: 16px;
        color: black !important;
    }
    #header-menu{
        margin: 0px auto;
        padding: 0px 8px;
        text-align: left;
    }
    .logo{
        height: 40px;
        margin: auto 0px
    }
    #header-top{
        height: 56px;
        padding: 0px 16px;
    }
    .dropdown-image{
        width: 120px !important;
        height: 120px !important;
        margin: 10px !important;
    }
    .menu-item-sub{
        color: black !important;
        margin-left: -15px;

    }
} 

@media only screen and (min-width: 1024px) {
    
    
}