.imageText-container{
    display: inline-flex;
    margin: 20px 0px;
}
.text-container{
    width:40%;
    display: flex;
    text-align: left;
    margin: 0px 5%;

}
.text-container-right{
    width:40%;
    display: flex;
    text-align: left;
    margin: 0px 5%;
}
.text{
    margin: auto;
    font-size: 16px;
    font-weight: 300;
}
.text > p{
    font-size: 16px;
    font-weight: 300;
}
.image-container{
    width: 50%;
    display: inline-block;
}
.image{
    width: 100%;
    /* border-radius: 30px; */
}


@media only screen and (max-width: 1024px) {
    .imageText-container{
        display: block;
        margin: 24px 0px;
    }
    .text-container{
        width: auto;
        display: flex;
        text-align: left;
        margin:24px 0px;
    
    }
    .text-container-right{
        width:auto;
        display: flex;
        text-align: left;
        margin:20px 0px;
    }
    .image-container{
        width: 100%;
        margin: 0px 0px;
        display: inline-block;
    }
} 