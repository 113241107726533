.specs-heading{
    text-align: left;
    padding: 10px;
    font-weight: lighter;
    font-size: 44px;
}

table{
    margin: auto;
    font-size: 14px;
}
.technical {
    text-align: center !important;
}

td {
    min-width: 160px;
    max-width: 300px;
    padding: 10px;
    font-size: 16px;
}

.price {
    color: #41644A;
    font-weight: 800;
    font-size: 18px;
}
.middle { 
    color: #41644A;
    font-size: 18px;
}
b{
    font-size: 18px;  
}

@media only screen and (max-width: 720px) {
    b{
        font-size: 16px;  
    }
    td {
        width: auto;
        min-width: auto;
        padding: 10px;
        font-size: 14px;
        color: #404040;
    
    }
    .spec-name{
        min-width: 120px;
    }

table{
    width: 100%;
}
.specs-heading{
    font-size: 36px;
}
}