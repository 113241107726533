.hero {
    width: 100%;
    max-width: 1360px;
    padding: auto 36px;
    display: flex !important;
    margin:auto
}

.hero1 {
    background-color: #F2E3DB;
}

.hero3 {
    background-color: #E86A33;
}

.hero-h {
    font-size: 40px;
    margin: auto 40px;
}

.hero-p {
    font-weight: 300;
    margin: 40px;
}


.hero-image {
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .hero{
        display: block !important;
        padding: auto 0px;
    }
    .hero-image {
        width: 80%;
        margin: auto;
    }
    .hero-h {
        font-size: 32px;
        margin:24px;
    }
    
    .hero-p {
        font-weight: 300;
        margin: auto 24px;
    }
   
}